<template>
  <div class="translate">
    <div class="cont">
      <div
        class="title bgc_ffffff questionAnswer"
        @click="imageChgange($event)"
        v-html="question.Title"
      ></div>
      <div class="ck_answer bgc_ffffff" v-if="show">
        <div class="title">【参考答案】</div>
        <div
          v-html="question.Answer"
          @click="imageChgange($event)"
          class="questionAnswer"
        ></div>
      </div>
      <div class="voc bgc_ffffff" v-if="route.type != 1">
        <div class="title">难点词汇</div>
        <div v-html="question.KeyWords"></div>
      </div>
      <div class="trunk bgc_ffffff" v-if="!show">
        <div class="title">找出主干</div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入"
          v-model="answer2"
        >
        </el-input>
      </div>
      <div class="fy bgc_ffffff" v-if="!show">
        <div class="title">翻译</div>
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入"
          v-model="answer"
        >
        </el-input>
      </div>
      <div class="answer bgc_ffffff" v-if="show">
        <div class="title">【我的答案】</div>
        <div>{{ this.route.exercises == 0 ? answer : question.Answer }}</div>
      </div>
    </div>
    <div class="btn_que" v-if="!show">
      <div class="act" @click="handleSubmit">提交</div>
    </div>
    <div class="btn_que" v-if="show">
      <!-- <div class="act">上一个</div> -->
      <div @click="next">下一个</div>
    </div>
    <div class="btn_que">
      <div @click="goHistory">返回上一页</div>
    </div>
  </div>
</template>
<script>
import {
  queryEnglishQuestion,
  englishPracticeSave,
  queryPracticeReocrd
} from "@/api/questionBank";
import { ImagePreview } from 'vant';
export default {
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      value: "",
      pageIndex: 1,
      pageSize: 1,
      route: {},
      answer: "",
      answer2: "",
      show: false,
      question: {},
      history: false,
    };
  },
  mounted () {
    // exercises 0 习题中心 1练习记录
    // 题目类型。0 句式分析，1 翻译、2 写作   type（0,1,2）
    this.route = this.$route.query
    if (this.route.type == 0) {
      document.title = "句式分析"
    }
    console.log(this.route, '')
    this.init()
  },
  methods: {
    goHistory () {
      this.$router.go(-1)
    },
    imageChgange (e) {
      if (e.target.nodeName == 'IMG') {
        // console.log(e.target.currentSrc, '')
        ImagePreview({
          images: [e.target.currentSrc],  //e.target.currentSrc  当前图片src
          showIndex: false,
          loop: false,
          closeable: true,
        })
      } else {
        return
      }
    },
    next () {
      this.answer = ""
      this.answer2 = ""
      this.question = {}
      this.init()
    },
    async handleSubmit () {
      if (!this.answer2) {
        this.$message.warning("请输入主干");
        return false
      }
      if (!this.answer) {
        this.$message.warning("请输入翻译");
        return false
      }
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        questionId: this.question.Id,
        answer: this.answer,
        answer2: this.answer2,
        questionCategory: this.route.type,
      }
      // 句式分析时传，分类ID
      if (this.route.CategroryId) parm.wordsCategoryId = this.route.CategroryId
      const res = await englishPracticeSave(parm);
      if (res.success == true) {
        this.show = true
      }
    },
    async init () {
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        questionCategory: this.route.type,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }
      // 句式分析时传，分类ID
      if (this.route.CategroryId) parm.wordsCategoryId = this.route.CategroryId
      const res = this.route.exercises == 0 ?
        await queryEnglishQuestion(parm) :
        await queryPracticeReocrd(parm)
      if (res.success == true) {
        if (this.route.exercises != 0) {
          this.show = true
        } else {
          this.show = false
        }
        if (res.response.data.length <= 0) {
          this.$message.warning("当前暂无更多答题");
          this.history = true
        }
        this.question = res.response.data[0]
      }
    },
  }
};
</script>
<style lang="less" scoped>
.translate {
  min-height: 100vh;
  .cont {
    .title {
      line-height: 100%;
      padding: 15px 20px;
      font-size: 15px;
      font-weight: bold;
    }
    .trunk,
    .fy,
    .answer,
    .voc {
      font-size: 15px;
      margin-top: 10px;
      padding: 0 20px 15px;
      .title {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .voc {
      span {
      }
      span + span {
        margin-left: 20px;
      }
    }
    .ck_answer {
      font-size: 15px;
      padding: 0 20px 15px;
      .title {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
.questionAnswer {
  /deep/img {
    max-width: 100%;
  }
}
</style>
